import { inject } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import cmntRoutes from './cmntRoutes'
import cscRoutes from './cscRoutes'
import findRoutes from './findRoutes'
import ginRoutes from './ginRoutes'
import infoRoutes from './infoRoutes'
import mainRoutes from './mainRoutes'
import memRoutes from './memRoutes'
import mypgRoutes from './mypgRoutes'
import servRoutes from './servRoutes'
import useAuthStore from '@/stores/useAuthStore'
import logger from '@/utils/loggerUtil.js'

/**
 * API
 */
import ginAPI from '@/api/gin'

const router = createRouter({
    history: createWebHistory(import.meta.env.VITE_BASE_URL),
    routes: [
        {
            path: '/:pathMatch(.*)',
            name: 'notFound',
            redirect: '/error'
        },
        {
            path: '/',
            name: 'index',
            redirect: { name: 'main' }
        },
        {
            path: '/',
            component: () => import('@/layouts/main/DefaultLayout.vue'),
            children: [
                {
                    name: 'error',
                    path: 'error',
                    component: () => import('@/views/base/BaseError.vue'),
                    meta: {
                        title: '에러:안내 페이지',
                        requiresAuth: false
                    }
                },
                {
                    name: 'auth',
                    path: 'auth',
                    component: () => import('@/views/base/BaseAuth.vue'),
                    meta: {
                        title: '권한:안내 페이지',
                        requiresAuth: false
                    }
                },
                {
                    name: 'message',
                    path: 'msg',
                    component: () => import('@/views/base/BaseMsg.vue'),
                    meta: {
                        title: '메시지:안내 페이지',
                        requiresAuth: false
                    }
                }
            ]
        },
        cmntRoutes,
        cscRoutes,
        findRoutes,
        ginRoutes,
        infoRoutes,
        mainRoutes,
        memRoutes,
        mypgRoutes.defaultLayoutRoutes,
        mypgRoutes.mypgLayoutRoutes,
        servRoutes
    ],

    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { top: 0 } // 라우팅 시 페이지 맨 위로 스크롤 이동
        }
    }
})

/**
 * 전역 beforEach 가드
 *
 * 네비게이션이 트리거될 때마다 가드가 작성 순서에 따라 호출되기 전의
 * 모든 경우에 발생합니다. 가드는 비동기식으로 실행 될 수 있으며
 * 네비게이션은 모든 훅이 해결되기 전까지 보류 중으로 간주됩니다.
 */
router.beforeEach(async (to, from, next) => {
    //동일 패스일 경우 쿼리를 초기화 해준다.
    if (to.path == from.path) {
        //쿼리 제거
        to.fullPath = to.path
        //쿼리 초기화
        to.query = {}
    }

    //저장소 정보 객체
    const authStore = useAuthStore()

    //로그인 연장 전역 변수
    const { updateGlobalLoginExtend } = inject('globalLoginExtend')

    if (authStore.isLogin() && from.name !== 'login') {
        //===> 로그인 상태 (로그인 화면에서 메인 화면 이동, 즉 로그인 시도 시 제외)
        //api: 사용자 토큰 갱신
        const data = await ginAPI.userTokenRefresh()

        if (data.success) {
            //===> 유효한 토큰일 경우
            //로그인 연장 전역 변수 업데이트
            updateGlobalLoginExtend(data)
            //패스
            next()
        } else {
            //===> 유효하지 않은 토큰일 경우
            //스토리지 로그아웃
            authStore.logout()
            //자동 로그아웃(동시접속) 안내 페이지 이동
            const params = {
                title: '로그아웃',
                step: '동일계정 동시 접속',
                text: '자동 로그아웃',
                message: `로그인 정책에 의해 동일계정 동시 접속시 기존 사용자는 로그아웃됩니다.
          서비스를 이용하시려면 다시 로그인 하세요.`,
                url: 'login',
                button: '로그인'
            }
            next({ name: 'message', state: { messageParams: params } })
        }
    } else if (to.meta.requiresAuth) {
        //===> 비로그인 상태에서 권한이 필요한 메뉴 접근시 안내 페이지 처리
        //로그인 페이지 이동
        next({ name: 'login' })
    } else {
        //===> 비로그인 상태에서 권한이 필요 없는 메뉴 접근
        //패스
        next()
    }
})

/**
 * 라우터 에러 전역 핸들러
 */
router.onError((e) => {
    logger.error(e.toString())
})

export default router
