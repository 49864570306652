const infoRoutes = {
    path: '/shc',
    component: () => import('@/layouts/main/DefaultLayout.vue'),
    children: [
        {
            name: 'shcInfoCont01',
            path: 'info/cont/01',
            component: () => import('@/views/shc/info/Cont01.vue'),
            meta: {
                title: '데이터블루-안내',
                requiresAuth: false
            }
        },
        {
            name: 'shcDataCont01',
            path: 'data/cont/01',
            component: () => import('@/views/shc/data/Cont01.vue'),
            meta: {
                title: '신한데이터유니버스-Gran Data',
                requiresAuth: false
            }
        },
        {
            name: 'shcDataCont02',
            path: 'data/cont/02',
            component: () => import('@/views/shc/data/Cont02.vue'),
            meta: {
                title: '신한데이터유니버스-My Data',
                requiresAuth: false
            }
        },
        {
            name: 'shcDataCont03',
            path: 'data/cont/03',
            component: () => import('@/views/shc/data/Cont03.vue'),
            meta: {
                title: '신한데이터유니버스-MyCREDIT',
                requiresAuth: false
            }
        },
        {
            name: 'shcDataCont04',
            path: 'data/cont/04',
            component: () => import('@/views/shc/data/Cont04.vue'),
            meta: {
                title: '신한데이터유니버스-데이터전문기관',
                requiresAuth: false
            }
        }
    ]
}

export default infoRoutes
