/**
 * 로그인 라우트
 */
const ginRoutes = {
    path: '/',
    component: () => import('@/layouts/main/DefaultLayout.vue'),
    children: [
        {
            name: 'login',
            path: 'gin',
            component: () => import('@/views/gin/DssGin.vue'),
            meta: {
                title: '로그인',
                requiresAuth: false
            }
        },
        {
            name: 'ginWkup',
            path: 'gin/wkup',
            component: () => import('@/views/gin/GinWkup.vue'),
            meta: {
                title: '로그인-휴면해제',
                requiresAuth: false
            }
        }
    ]
}

export default ginRoutes
