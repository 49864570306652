const memRoutes = {
    path: '/mem',
    component: () => import('@/layouts/main/DefaultLayout.vue'),
    children: [
        {
            name: 'memType',
            path: 'memtype',
            component: () => import('@/views/mem/MemType.vue'),
            meta: {
                title: '회원가입-회원 유형 선택',
                requiresAuth: false
            }
        },
        {
            name: 'memPsn',
            path: 'mempsn',
            component: () => import('@/views/mem/MemPsn.vue'),
            meta: {
                title: '회원가입-개인-본인인증',
                requiresAuth: false
            }
        },
        {
            name: 'memPsnEtk',
            path: 'mempsnetk',
            component: () => import('@/views/mem/MemPsnEtk.vue'),
            meta: {
                title: '회원가입-개인사업자',
                requiresAuth: false
            }
        },
        {
            name: 'memCrpEtk',
            path: 'memcrpetk',
            component: () => import('@/views/mem/MemCrpEtk.vue'),
            meta: {
                title: '회원가입-법인사업자',
                requiresAuth: false
            }
        }
    ]
}

export default memRoutes
