import $axios from '@/plugins/axiosInterceptor'
import commonUtil from '@/utils/commonUtil'

/**
 * 팝업공지 조회
 *
 * 메인에 사용할 팝업공지를 조회한다.
 *
 * @returns 조회 결과
 */
const mainPopbannerList = async () => {
    //결과
    let data = {}

    //API 호출
    await $axios
        .get('/api/v1/main/popbanner/list')
        .then((res) => {
            //성공
            data = res.data
        })
        .catch((error) => {
            //실패
            data = error.data
        })

    return data
}

/**
 * 배너 조회
 *
 * 메인 상단에 사용할 배너를 조회한다.
 *
 * @returns 조회 결과
 */
const mainTopbannerList = async () => {
    //결과
    let data = {}

    //API 호출
    await $axios
        .get('/api/v1/main/topbanner/list')
        .then((res) => {
            //성공
            data = res.data
        })
        .catch((error) => {
            //실패
            data = error.data
        })

    return data
}

/**
 * Key Visual 조회
 *
 * 메인 Key Visual 영역에 사용할 Key Visual을 조회한다.
 *
 * @returns 조회 결과
 */
const mainKeyvisualList = async () => {
    //결과
    let data = {}

    //API 호출
    await $axios
        .get('/api/v1/main/keyvisual/list')
        .then((res) => {
            //성공
            data = res.data
        })
        .catch((error) => {
            //실패
            data = error.data
        })

    return data
}

/**
 * 알림 뱃지 표시 조회
 *
 * 메인 로그인 퀵 영역에 사용할 알림 뱃지 표시를 조회한다.
 *
 * @returns 조회 결과
 */
const mainInformNewcount = async () => {
    let data = {}

    const params = {
        pageIndex: 1,
        rowsPerPage: 10,
        ntfcCfF: 'N'
    }

    //API 호출
    await $axios
        .get('/api/v1/notification/list', { params })
        .then((res) => {
            //성공
            data = res.data
        })
        .catch((error) => {
            //실패
            data = error.data
        })

    return data
}

/**
 * 활용장 조회
 *
 * 메인 로그인 퀵 영역에 사용할 활용장을 조회한다.
 *
 * @returns 조회 결과
 */
const mainUsecaseList = async () => {
    //결과
    let data = {}

    //API 호출
    await $axios
        .get('/api/v1/main/usecase/list')
        .then((res) => {
            //성공
            data = res.data
        })
        .catch((error) => {
            //실패
            data = error.data
        })

    return data
}

/**
 * 추천 검색어 조회
 *
 * 메인 검색 영역에 사용할 추천 검색어를 조회한다.
 *
 * @returns 조회 결과
 */
const mainKeywordList = async () => {
    //결과
    let data = {}

    //API 호출
    await $axios
        .get('/api/v1/main/keyword/list')
        .then((res) => {
            //성공
            data = res.data
        })
        .catch((error) => {
            //실패
            data = error.data
        })

    return data
}

/**
 * 서비스 검색
 *
 * 검색 값을 통해 서비스 상품을 검색한다.
 *
 * @returns 검색 결과
 */
const searchService = async (currentPage, searchKeyword, productTypeCode) => {
    //결과
    let data = {}

    //페이지 초기화
    if (!currentPage) currentPage = 1

    //조건
    const params = {
        pageIndex: currentPage,
        rowsPerPage: import.meta.env.VITE_ROWS_PER_PAGE,
        searchKeyword: searchKeyword,
        productTypeCode: productTypeCode === '00' ? null : productTypeCode
    }

    //API 호출
    await $axios
        .get('/api/v1/product/service/allsearch/list', { params })
        .then((res) => {
            //성공
            data = res.data
        })
        .catch((error) => {
            //실패
            data = error.data
        })

    return data
}

/**
 * Most Popular Services 조회
 *
 * 메인 하단 영역에 사용할 MPS를 조회한다.
 *
 * @returns 조회 결과
 */
const mainMpsList = async (currentPage) => {
    //결과
    let data = {}

    //페이지 초기화
    if (!currentPage) currentPage = 1

    //조건 (모바일: 4개씩, PC: 6개씩)
    const params = {
        pageIndex: currentPage,
        rowsPerPage: commonUtil.isMobile() ? 4 : 6
    }

    //API 호출
    await $axios
        .get('/api/v1/main/mps/list', { params })
        .then((res) => {
            //성공
            data = res.data
        })
        .catch((error) => {
            //실패
            data = error.data
        })

    return data
}

/**
 * 공지사항 조회
 *
 * 메인 하단 영역에 사용할 공지사항을 조회한다.
 *
 * @returns 조회 결과
 */
const noticeList = async (currentPage) => {
    //결과
    let data = {}

    //페이지 초기화
    if (!currentPage) currentPage = 1

    //조건 (모바일: 4개씩, PC: 6개씩)
    const params = {
        pageIndex: currentPage,
        rowsPerPage: 3
    }

    //API 호출
    await $axios
        .get('/api/v1/notice/list', { params })
        .then((res) => {
            //성공
            data = res.data
        })
        .catch((error) => {
            //실패
            data = error.data
        })

    return data
}

/**
 * 임시 로그인 API
 *
 * 메인화면 임시 로그인 팝업에서 사용 할 로그인 API
 *
 * @returns 응답 결과
 */
const temporaryLogin = async (req) => {
    //조건
    const paramsObject = {
        userId: req.id,
        password: req.password
    }

    //결과
    let data = {}

    //API 호출
    await $axios
        .post('/api/v1/test/user/login', paramsObject)
        .then((res) => {
            //성공
            data = res.data
        })
        .catch((error) => {
            //실패
            data = error.data
        })
    return data
}

export default {
    mainPopbannerList,
    mainTopbannerList,
    mainKeyvisualList,
    mainInformNewcount,
    mainUsecaseList,
    mainKeywordList,
    searchService,
    mainMpsList,
    noticeList,
    temporaryLogin
}
