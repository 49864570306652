import routeUtil from '@/utils/routeUtil'

/**
 * 메인 라우트
 */
const mainRoutes = {
    path: '/',
    component: () => import('@/layouts/main/MainLayout.vue'),
    children: [
        {
            name: 'main',
            path: 'main',
            component: () => import('@/views/main/DssMain.vue'),
            beforeEnter: [routeUtil.settingHistoryStore],
            meta: {
                title: '메인',
                requiresAuth: false
            }
        },
        {
            name: 'search',
            path: 'search',
            component: () => import('@/views/main/DssSearch.vue'),
            beforeEnter: [routeUtil.settingHistoryStore],
            meta: {
                title: '메인-검색결과',
                requiresAuth: false
            }
        }
    ]
}

export default mainRoutes
