import routeUtil from '@/utils/routeUtil'

const servRoutes = {
    path: '/serv',
    component: () => import('@/layouts/main/DefaultLayout.vue'),
    children: [
        {
            name: 'servDataProdSrch',
            path: 'data/prod/srch',
            component: () => import('@/views/serv/data/DataProdSrch.vue'),
            beforeEnter: [routeUtil.settingHistoryStore],
            meta: {
                title: '데이터상품-목록',
                requiresAuth: false
            }
        },
        {
            name: 'servDataProdDetl',
            path: 'data/prod/detl/:id',
            component: () => import('@/views/serv/data/DataProdDetl.vue'),
            meta: {
                title: '데이터상품-상세',
                requiresAuth: false,
                requiresHistory: ['servDataProdSrch', 'main', 'search', 'mypgProd']
            }
        },
        {
            name: 'servDataProdCont01',
            path: 'data/prod/cont/01',
            component: () => import('@/views/serv/data/DataProdCont01.vue'),
            meta: {
                title: '데이터상품-맞춤데이터',
                requiresAuth: false
            }
        },
        {
            name: 'servSltnProdSrch',
            path: 'sltn/prod/srch',
            component: () => import('@/views/serv/sltn/SltnProdSrch.vue'),
            beforeEnter: [routeUtil.settingHistoryStore],
            meta: {
                title: '솔루션상품-목록',
                requiresAuth: false
            }
        },
        {
            name: 'servSltnProdDetl',
            path: 'sltn/prod/detl/:id',
            component: () => import('@/views/serv/sltn/SltnProdDetl.vue'),
            meta: {
                title: '솔루션상품-상세',
                requiresAuth: false,
                requiresHistory: ['servSltnProdSrch', 'main', 'search', 'mypgProd']
            }
        },
        {
            name: 'servApisProdSrch',
            path: 'apis/prod/srch',
            component: () => import('@/views/serv/apis/ApisProdSrch.vue'),
            beforeEnter: [routeUtil.settingHistoryStore],
            meta: {
                title: 'API상품-목록',
                requiresAuth: false
            }
        },
        {
            name: 'servApisProdDetl',
            path: 'apis/prod/detl/:id',
            component: () => import('@/views/serv/apis/ApisProdDetl.vue'),
            meta: {
                title: 'API상품-상세',
                requiresAuth: false,
                requiresHistory: ['servApisProdSrch', 'main', 'search', 'mypgProd']
            }
        },
        {
            name: 'servApisProdInfo',
            path: 'apis/prod/info',
            component: () => import('@/views/serv/apis/ApisProdInfo.vue'),
            meta: {
                title: 'API상품-안내',
                requiresAuth: false
            }
        },
        {
            name: 'servAdvtProdCont01',
            path: 'advt/prod/cont/01',
            component: () => import('@/views/serv/advt/AdvtProdCont01.vue'),
            meta: {
                title: '광고서비스-타겟마케팅',
                requiresAuth: false
            }
        },
        {
            name: 'servAdvtProdCont02',
            path: 'advt/prod/cont/02',
            component: () => import('@/views/serv/advt/AdvtProdCont02.vue'),
            meta: {
                title: '광고서비스-서베이대행서비스',
                requiresAuth: false
            }
        },
        {
            name: 'servAdvtProdCont03',
            path: 'advt/prod/cont/03',
            component: () => import('@/views/serv/advt/AdvtProdCont03.vue'),
            meta: {
                title: '광고서비스-SoL-Pay배너광고',
                requiresAuth: false
            }
        }
    ]
}

export default servRoutes
