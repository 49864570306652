/**
 * 로그인 정보 저장소 객체
 *
 * 로그인 정보를 저장소에 저장한다.
 */
import { inject, ref, toRef } from 'vue'
import { defineStore } from 'pinia'
import { EncryptStorage } from 'encrypt-storage'
import * as Sentry from '@sentry/vue'
import ctsnoUtil from '@/utils/ctsnoUtil'
import ginAPI from '@/api/gin'
import mainAPI from '@/api/main'

//암복호화 객체
const encryptStorage = new EncryptStorage(import.meta.env.VITE_STORAGE_SECRET_KEY, { prefix: '@DSS' })

//로그인 정보 객체
const useAuthStore = defineStore('auth', () => {
    //로그인 여부
    const loginOrNot = ref(false)

    ///알림 전역 변수
    const { updateGlobalAlarmOn } = inject('globalAlarmOn')

    const timer = {
        ginVdTmInfo: null,
        ginSduEdInfo: null
    }

    //로그인 유효시간 타이머 정보 객체 생성
    let ginVdTmInfo = null

    //로그인 만료예정시간 타이머 정보 객체 생성
    let ginSduEdInfo = null

    /**
     * 토큰 정보 저장
     *
     * 현재 시간에 ms(ttl) 시간을 더해 만료 시간을 설정한다.
     * 유효시간은 10분이나 보정을 위해 11분으로 최종시간을 지정한다.
     */
    const setWithExpiry = (mode, key, results, ttl) => {
        //현재 시간
        const now = new Date()

        //스토리지에 정보할 정보
        let item = {}

        if (mode === 'LGIN') {
            //스토리지 로그인 정보 세팅
            item = {
                userKey: results.user.dbsnCusN,
                userNm: results.user.cusNm
            }
            //Sentry userid 세팅
            Sentry.setUser({ id: item.userKey })
        } else if (mode === 'LXTS') {
            //기존 스토리지 정보 획득
            item = getWithExpiry('store')
        }

        //유효시간
        item.expiry = now.getTime() + Number(ttl) + Number(import.meta.env.VITE_LOGIN_EXTRA_TIME)

        //스토리지에 저장
        encryptStorage.setItem(key, JSON.stringify(item))
    }

    /**
     * 토큰 정보 획득
     *
     * 키 값에 해당하는 스토리지 정보를 반환한다.
     */
    const getWithExpiry = (key) => {
        //키에 해당하는 스토리지 정보 획득
        const itemStr = encryptStorage.getItem(key)

        //미존재 시 null 반환
        if (!itemStr) {
            return null
        }

        const item = itemStr
        const now = new Date()

        //유효시간이 만료된 경우 스토리지 정보 삭제 후 null 반환
        if (now.getTime() > Number(item.expiry)) {
            encryptStorage.removeItem(key)
            return null
        }

        return item
    }

    /**
     * 로그인 여부
     *
     * 스토리지에 존재하는 로그인 여부를 반환한다.
     * @returns true or false
     */
    const isLogin = () => {
        return getWithExpiry('store') !== null
    }

    /**
     * 로그인
     *
     * 토근 정보를 스토리지에 저장한다.
     */
    const login = (results) => {
        //스토리지 로그인 정보 저장
        setWithExpiry('LGIN', 'store', results, import.meta.env.VITE_LOGIN_VALIDITY_TIME)

        //초기 설정
        initSettings()

        //알림 조회
        inquiryAlarm()
    }

    /**
     * 로그아웃
     *
     * 스토리지 로그인 정보 삭제 및 로그인 상태를 변경한다.
     */
    const logout = () => {
        //스토리지 로그인 정보 삭제
        encryptStorage.removeItem('store')

        //Sentry userid 세팅
        Sentry.setUser({ id: '' })

        //타이머 종료
        timerStop()

        //초기 설정
        initSettings()

        //알림 전역 변수
        updateGlobalAlarmOn('')
    }

    /**
     * 느슨한 로그아웃
     *
     * 회원탈퇴/계정전환 시 API 토큰 만료 및 스토리지 로그인 정보 삭제 및 로그인 상태를 변경한다.
     * API 토큰 만료는 성공 여부와 상관없이 나머지 로그아웃 과정을 진행한다.
     */
    const looseLogout = async () => {
        //API: 사용자 로그아웃
        await ginAPI.userLogout()

        //스토리지 로그인 정보 삭제
        encryptStorage.removeItem('store')

        //Sentry userid 세팅
        Sentry.setUser({ id: '' })

        //타이머 종료
        timerStop()

        //초기 설정
        initSettings()

        //알림 전역 변수
        updateGlobalAlarmOn('')
    }

    /**
     * 타이머 생성
     *
     * 타이머 객체를 생성한다.
     */
    const timerInit = (target, callback) => {
        if (target === 'out') {
            //로그인 유효시간 타이머 정보 객체 생성
            timer.ginVdTmInfo = toRef(new ctsnoUtil.CtsNo(import.meta.env.VITE_LOGIN_VALIDITY_TIME, callback))
        } else if (target === 'xts') {
            //로그인 만료예정시간 타이머 정보 객체 생성
            timer.ginSduEdInfo = toRef(new ctsnoUtil.CtsNo(import.meta.env.VITE_LOGIN_SCHEDULED_END_TIME, callback))
        }
    }

    /**
     * 타이머 종료
     *
     * 기존 작동하고 있는 타이머를 종료한다.
     */
    const timerStop = () => {
        //로그인 유효시간 타이머 중지 및 웹 워커 종료
        if (timer['ginVdTmInfo']) timer['ginVdTmInfo'].value.timerStop()
        //로그인 만료예정시간 타이머 중지 및 웹 워커 종료
        if (timer['ginSduEdInfo']) timer['ginSduEdInfo'].value.timerStop()
    }

    /**
     * 로그인 유효시간 연장
     *
     * 스토리지 로그인 정보 삭제 후 새로 발급 받은 토큰을 저장한다.
     */
    const loginTmXts = (results) => {
        //스토리지 로그인 정보 저장
        setWithExpiry('LXTS', 'store', results, import.meta.env.VITE_LOGIN_VALIDITY_TIME)
    }

    /**
     * 아이디 저장 정보 저장
     *
     * 로그인 '아이디 저장' 선택시 스토리지에 해당 값을 저장한다.
     * @param {*} value
     */
    const setRecycle = (value) => {
        const item = {
            value: value
        }

        encryptStorage.setItem('recycle', JSON.stringify(item))
    }

    /**
     * 아이디 저장 정보 획득
     *
     * 아이디 저장 스토리지 정보를 반환한다.
     */
    const getRecycle = () => {
        const itemStr = encryptStorage.getItem('recycle')

        if (!itemStr) {
            return null
        }

        return itemStr.value
    }

    /**
     * 아이디 저장 정보 삭제
     *
     * 로그인 '아이디 저장' 해제시 스토리지에 해당 값을 삭제한다.
     */
    const removeRecycle = () => {
        encryptStorage.removeItem('recycle')
    }

    /**
     * 초기 설정
     *
     * 로그인 여부 및 사용자 정보를 현행화한다.
     */
    const initSettings = () => {
        //로그인 여부
        loginOrNot.value = isLogin()
    }

    /**
     * 알림 조회
     *
     * 메인 및 마이 영역에 사용할 알림 여부를 조회한다.
     */
    const inquiryAlarm = async () => {
        //API: 알림 뱃지 표시 조회
        const data = await mainAPI.mainInformNewcount()

        if (data.success) {
            //알림 설정
            if (data.results.page.totalCount > 0) {
                updateGlobalAlarmOn('on')
            }
        }
    }

    return {
        loginOrNot,
        timer,
        ginVdTmInfo,
        ginSduEdInfo,
        getWithExpiry,
        isLogin,
        login,
        logout,
        looseLogout,
        timerInit,
        timerStop,
        loginTmXts,
        setRecycle,
        getRecycle,
        removeRecycle,
        initSettings,
        inquiryAlarm
    }
})

export default useAuthStore
