import axios from 'axios'

// Credentials 기본 설정
axios.defaults.withCredentials = true

/**
 * axios 인스턴스 생성
 */
const $axios = axios.create({
    baseURL: import.meta.env.VITE_LOCAL_API_BASE_URL
        ? import.meta.env.VITE_LOCAL_API_BASE_URL
        : import.meta.env.VITE_API_BASE_URL,
    timeout: import.meta.env.VITE_API_TIMEOUT
})

/**
 * 요청 인터셉터
 */
$axios.interceptors.request.use(
    (config) => {
        return config
    },
    (error) => {
        //요청 오류
        return Promise.reject(error)
    }
)

/**
 * 응답 인터셉터
 */
$axios.interceptors.response.use(
    (response) => {
        //응답 전달 전

        //STR: 업무상 실제 성공 여부 추가
        const stream = response.headers['content-type'] === 'application/octet-stream'
        let success = false
        let errorMessage = ''

        if (response.data.code === '20000') {
            //===> 일반 응답
            //상세 결과
            if (
                typeof response.data.results === 'undefined' ||
                response.data.results === null ||
                typeof response.data.results.result === 'undefined' ||
                response.data.results.result
            ) {
                //성공: 상세 결과 미정의
                //성공: 상세 결과 없음
                //성공: 상세 결과-결과 없음
                //성공: 상세 결과-결과 참
                success = true
            }
        } else if (stream) {
            //===> 스트림 응답
            if (response.status === 200) {
                success = true
            }
        }

        if (!success) {
            //실패일 경우 에러 메시지
            if (!response?.data?.results?.result) {
                errorMessage = response?.data?.results?.resultMessage
            } else if (response?.data) {
                errorMessage = response?.data?.message
            } else {
                errorMessage = '오류가 발생했습니다.'
            }
        }

        if (stream) {
            //===> 스트림 응답
            response.success = success
            response.errorMessage = errorMessage
        } else {
            //===> 일반 응답
            response.data.success = success
            response.data.errorMessage = errorMessage
        }
        //END: 업무상 실제 성공 여부 추가

        return response
    },
    async (error) => {
        let data = {}

        if (error.response.data) {
            //요청 전송 성공, 응답 수신 성공(2xx 외 상태 코드)
            data = error.response.data
            data.errorMessage = error.response.data.message
        } else {
            //요청 전송 성공, 응답 수신 실패('error.request'는 브라우저에서 XMLHttpRequest 인스턴스)
            //오류가 발생한 요청을 설정하는 동안 문제 발생
            data.code = error.code
            data.errorMessage = error.message
        }

        //에러 데이터
        error.data = data
        //업무상 실제 성공 여부
        error.data.success = false

        //ECONNREFUSED, ECONNABORTED, ERR_NETWORK
        return Promise.reject(error)
    }
)

export default $axios
