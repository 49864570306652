import App from './App.vue'
import { createApp, ref } from 'vue'
import { createPinia } from 'pinia'
import router from './router'
import VueCookies from 'vue3-cookies'
import { decode } from 'html-entities'
import moment from 'moment'
import { createMetaManager, plugin as metaPlugin } from 'vue-meta'
import { createGtm } from '@gtm-support/vue-gtm'

//STR sentry
import * as Sentry from '@sentry/vue'
//END sentry
//STR Publish
import vuetify from './plugins/vuetify'
import '@/scss/style.scss'
import '@/assets/scss/style.scss'
//END Publish

//앱 생성
const app = createApp(App)

//sentry 초기화
if (import.meta.env.MODE !== 'production') {
    Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,

        // performance 설정
        integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
        tracesSampleRate: 1.0, // debug 1.0, release 0.2
        replaysOnErrorSampleRate: 1.0
    })
}

//글로벌 필터
app.config.globalProperties.$filters = {
    /**
     * 날짜 형식
     *
     * 문자 형태의 날짜를 지정된 날짜 형식으로 변환한다.
     *
     * @param {string} date
     * @param {string} order
     * @returns order 지정이 없을 경우 기본 `YYYY.MM.DD` 형식으로 반환한다.
     */
    dateFormat(date, order) {
        if (order) {
            return moment(date).format(order)
        } else {
            return moment(date).format('YYYY.MM.DD')
        }
    },
    /**
     * 언이스케이프
     *
     * 문자열을 언이스케이프 처리한다.
     *
     * @param {string} value
     * @returns unescape 문자열을 반환한다.
     */
    unescape(value) {
        return decode(value, { level: 'html5' })
    }
}

//로그인 연장 전역 변수
const globalLoginExtend = ref()
const updateGlobalLoginExtend = (v) => {
    globalLoginExtend.value = v
}
app.provide('globalLoginExtend', { globalLoginExtend, updateGlobalLoginExtend })

//알림 전역 변수
const globalAlarmOn = ref()
const updateGlobalAlarmOn = (v) => {
    globalAlarmOn.value = v
}
app.provide('globalAlarmOn', { globalAlarmOn, updateGlobalAlarmOn })

//pinia 사용 설정
app.use(createPinia())
//라우터 사용 설정
app.use(router)
//쿠키 사용 설정
app.use(VueCookies)
//메타 사용 설정
app.use(createMetaManager())
app.use(metaPlugin)

//STR Public
app.use(vuetify)
//END Public

//google for google analytics
if (import.meta.env.MODE === 'production') {
    app.use(
        createGtm({
            id: 'GTM-W96CH96V',
            vueRouter: router
        })
    )
}

router.isReady().then(() => {
    //앱 마운트
    app.mount('#app')
})
