/**
 * 인증유효시간
 *
 * 웹 워커 방식으로 작동하며 인증유효시간을 mm:ss 포멧으로 표시한다.
 *
 * (예) 인증유효시간 설정
 * import ctsnoUtil from '@/utils/ctsnoUtil.js'
 * const cssnVdInfo = reactive(new ctsnoUtil.CtsNo(60 * 4), callback) //직접 값 입력
 *   or
 * const cssnVdInfo = reactive(
 *    new ctsnoUtil.CtsNo(import.meta.env.VITE_LOGIN_VALIDITY_TIME),
 *    callback
 * ) //환경설정 값 입력
 *
 * <span v-if="cssnVdInfo.cha">
 *   {{ cssnVdInfo.cha }}
 * </span>
 *
 * <v-btn @click="cssnVdInfo.timerStart()">인증유효시간 시작</v-btn>
 * <v-btn @click="cssnVdInfo.timerStop()">인증유효시간 중지</v-btn>
 *
 * <script setup>
 * const callback = () => {
 *   something...
 * }
 * </script>
 */
const CtsNo = class {
    //생성자
    constructor(ct, callback) {
        //유효 시간
        this.ct = ct / 1000
        //유효 시간 표시
        this.cha = ''
        //콜백 함수(유효시간 만료시 호출)
        this.callback = callback
        //웹 워커
        this.worker = new Worker(new URL('timerUtil.js', import.meta.url))
    }

    /**
     * 인증번호 유효시간 시작
     *
     * Interval 객체 존재시 초기화하고 새 Interval 객체로 대체한다.
     * 유효 시간이 초과할 경우 타이머를 중지한다.
     */
    timerStart(t) {
        const timer = {
            cmd: 'start',
            time: typeof t !== 'undefined' ? t / 1000 : this.ct
        }

        //메세지 발신
        this.worker.postMessage(timer)
        //메세지 수신
        this.worker.onmessage = (e) => {
            this.cha = e.data
            if (this.cha === '00:00' && typeof this.callback === 'function') this.callback.call()
        }
    }

    /**
     * 인증번호 유효시간 중지
     *
     * 인증유효시간 객체를 초기화한다.
     */
    timerStop() {
        const timer = {
            cmd: 'stop',
            time: 0
        }

        //메세지 발신
        this.worker.postMessage(timer)
        //메세지 수신
        this.cha = ''

        //웹 워커 종료
        this.worker.terminate()
    }
}

export default {
    CtsNo
}
