const findRoutes = {
    path: '/',
    component: () => import('@/layouts/main/DefaultLayout.vue'),
    children: [
        {
            name: 'findIdPinMf',
            path: 'findIdPinMf',
            component: () => import('@/views/find/FindIdPinMf.vue'),
            meta: {
                title: '아이디 찾기·비밀번호 변경',
                requiresAuth: false
            }
        }
    ]
}

export default findRoutes
