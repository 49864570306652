import routeUtil from '@/utils/routeUtil'

/**
 * 고객센터 라우트
 */
const cscRoutes = {
    path: '/csc',
    component: () => import('@/layouts/main/DefaultLayout.vue'),
    children: [
        {
            name: 'cscAnc',
            path: 'anc',
            component: () => import('@/views/csc/anc/CscAnc.vue'),
            beforeEnter: [routeUtil.settingHistoryStore],
            meta: {
                title: '공지사항-목록',
                requiresAuth: false
            }
        },
        {
            name: 'cscAncDetl',
            path: 'anc/detl/:id',
            component: () => import('@/views/csc/anc/CscAncDetl.vue'),
            meta: {
                title: '공지사항-상세',
                requiresAuth: false,
                requiresHistory: ['cscAnc', 'main']
            }
        },
        {
            name: 'cscFaq',
            path: 'faq',
            component: () => import('@/views/csc/faq/CscFaq.vue'),
            meta: {
                title: 'FAQ-목록',
                requiresAuth: false
            }
        },
        {
            name: 'cscQna',
            path: 'qna',
            component: () => import('@/views/csc/qna/CscQna.vue'),
            beforeEnter: [routeUtil.settingHistoryStore],
            meta: {
                title: '고객문의-목록',
                requiresAuth: false
            }
        },
        {
            name: 'cscQnaDetl',
            path: 'qna/detl/:id',
            component: () => import('@/views/csc/qna/CscQnaDetl.vue'),
            meta: {
                title: '고객문의-상세',
                requiresAuth: true,
                requiresHistory: ['cscQna', 'mypgQna']
            }
        },
        {
            name: 'cscQnaReg',
            path: 'qna/reg/:id?',
            component: () => import('@/views/csc/qna/CscQnaReg.vue'),
            meta: {
                title: '고객문의-등록',
                requiresAuth: true
            }
        },
        {
            name: 'cscQnaAlt',
            path: 'qna/alt/:id',
            component: () => import('@/views/csc/qna/CscQnaAlt.vue'),
            meta: {
                title: '고객문의-수정',
                requiresAuth: true
            }
        }
    ]
}

export default cscRoutes
