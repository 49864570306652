import routeUtil from '@/utils/routeUtil'

/**
 * 커뮤니티 라우트
 */
const cmntRoutes = {
    path: '/cmnt',
    component: () => import('@/layouts/main/DefaultLayout.vue'),
    children: [
        {
            name: 'cmntUses',
            path: 'uses',
            component: () => import('@/views/cmnt/uses/CmntUsesCtg.vue'),
            beforeEnter: [routeUtil.settingHistoryStore],
            meta: {
                title: '활용장-목록',
                requiresAuth: false
            }
        },
        {
            name: 'cmntUsesDetl',
            path: 'uses/detl/:id',
            component: () => import('@/views/cmnt/uses/CmntUsesDetl.vue'),
            meta: {
                title: '활용장-상세',
                requiresAuth: false,
                requiresHistory: ['cmntUses']
            }
        },
        {
            name: 'cmntDisc',
            path: 'disc',
            component: () => import('@/views/cmnt/disc/CmntDisc.vue'),
            beforeEnter: [routeUtil.settingHistoryStore],
            meta: {
                title: '토론장-목록',
                requiresAuth: false
            }
        },
        {
            name: 'cmntDiscDetl',
            path: 'disc/detl/:id',
            component: () => import('@/views/cmnt/disc/CmntDiscDetl.vue'),
            meta: {
                title: '토론장-상세',
                requiresAuth: true,
                requiresHistory: ['cmntDisc']
            }
        },
        {
            name: 'cmntDiscReg',
            path: 'disc/reg',
            component: () => import('@/views/cmnt/disc/CmntDiscReg.vue'),
            meta: {
                title: '토론장-등록',
                requiresAuth: true
            }
        },
        {
            name: 'cmntDiscAlt',
            path: 'disc/alt/:id',
            component: () => import('@/views/cmnt/disc/CmntDiscAlt.vue'),
            meta: {
                title: '토론장-수정',
                requiresAuth: true
            }
        }
    ]
}

export default cmntRoutes
