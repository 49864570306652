import $axios from '@/plugins/axiosInterceptor'

/**
 * 사용자 로그인
 *
 * 로그인 정보를 기반으로 사용자를 로그인 처리한다.
 */
const userLogin = async (req) => {
    //요청 정보
    let userInfo = {
        userId: req.acc
    }

    //비밀번호 세팅
    if (req.ecrPin) {
        //암호화된 비밀번호 값이 있을 경우
        userInfo.passwordObj = req.ecrPin
    } else {
        //평문 비밀번호일 경우
        userInfo.password = req.pin
    }

    //결과
    let data = {}

    //API 호출
    await $axios
        .post('/api/v1/user/login?masking=N', userInfo)
        .then((res) => {
            //성공
            data = res.data
        })
        .catch((error) => {
            //실패
            data = error.data
        })

    return data
}

/**
 * 사용자 로그아웃
 *
 * 로그인 정보를 기반으로 사용자를 로그아웃 처리한다.
 */
const userLogout = async () => {
    //결과
    let data = {}

    //API 호출
    await $axios
        .get('/api/v1/user/logout')
        .then((res) => {
            //성공
            data = res.data
        })
        .catch((error) => {
            //실패
            data = error.data
        })

    return data
}

/**
 * 사용자 토큰 갱신
 *
 * 로그인 연장 개념으로 새로 토큰을 발급 받는다.
 */
const userTokenRefresh = async () => {
    //결과
    let data = {}

    //API 호출
    await $axios
        .get('/api/v1/user/token/refresh')
        .then((res) => {
            //성공
            data = res.data
        })
        .catch((error) => {
            //실패
            data = error.data
        })

    return data
}

/**
 * 사용자 토큰 체크
 *
 * 토큰 유효성을 체크한다.
 */
const userTokenCheck = async () => {
    //결과
    let data = {}

    //API 호출
    await $axios
        .get('/api/v1/user/token/check')
        .then((res) => {
            //성공
            data = res.data
        })
        .catch((error) => {
            //실패
            data = error.data
        })

    return data
}

/**
 * 휴면계정전환
 *
 * 본인 인증 정보를 기반으로 휴면계정을 해제한다.
 */
const userEnable = async (req) => {
    //요청 정보
    const memPsnInfo = {
        dbsnHdpCtsRqN: req.ctserverkeyhon,
        cusNm: req.nm,
        vrfRqSsn: `${req.bdy}${req.sex}`,
        hon: req.hon,
        cmuCoCd: req.telType === '0099' ? req.savingTelType : req.telType
    }

    //결과
    let data = {}

    //API 호출
    await $axios
        .post('/api/v1/user/enable', memPsnInfo)
        .then((res) => {
            //성공
            data = res.data
        })
        .catch((error) => {
            //실패
            data = error.data
        })

    return data
}

export default { userLogin, userLogout, userTokenRefresh, userTokenCheck, userEnable }
